import React from 'react'
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import Carousel from 'react-multi-carousel';

import Content from './content';

import styles from '../styles/customers.module.scss';
import 'react-multi-carousel/lib/styles.css';

function chunkArray(myArray, chunk_size){
    var results = [];

    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size));
    }

    return results;
}

const Customers = (props) => {
	return <StaticQuery
	  query={customersQuery}
	  render={ data => {
      const chunkedCustomers = chunkArray(data.allContentfulCustomer.edges.slice(), 2);
      const customers = chunkedCustomers.map((chunk, index) => {
        return <div className={styles.customersSlide} key={chunk[0].node.id}>
          <CustomerLogoItem {...chunk[0].node} />
          {!!chunk[1] && <CustomerLogoItem {...chunk[1].node} />}
        </div>
      });

      const carouselSettings = {
        responsive: {
          mobile: {
            breakpoint: { max: 640, min: 0 },
            items: 2,
          },
          desktop: {
            breakpoint: { max: 4000, min: 640 },
            items: 4,
          },
        },
        draggable: false,
        swipable: false,
        arrows: false,
        showDots: false,
        infinite: true,
        autoPlay: true,
        autoPlaySpeed: 1,
        customTransition: 'all 1.2s linear',
        transitionDuration: 1200,
        // centerMode: true,
      };

	    return <div className={styles.customers}>
				<Carousel {...carouselSettings} className={styles.customersSlider}>
					{customers}
				</Carousel>
			</div>
	  }}
	/>
}

const CustomerLogoItem = ({ type, name, body, website, logo }) => {
  return <div className={styles.customersLogoItem}>
    <div className={styles.logo}>
      {!!logo && <Img className={styles.logoImg} loading='eager' {...logo} />}
    </div>
  </div>
}

const CustomerListItem = ({ type, name, body, website, logo }) => {
	return <div className={styles.customersListItem}>
    <div className={styles.logo}>
      {!!logo && <Img {...logo} />}
    </div>
    <div className={styles.info}>
      <h3>{name}</h3>
      {!!body && <Html {...body.childMarkdownRemark} />}
    </div>
  </div>
}

const Html = ({ html }) => (
  <div
    className={styles.html}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

CustomerListItem.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

const customersQuery = graphql`
  query Customers {
    allContentfulCustomer {
      edges {
        node {
          id
          slug
          name
          body {
            childMarkdownRemark {
              html
            }
          }
          logo {
            id
            title
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export { Customers as default };
