import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from 'react-google-maps';

import styles from '../styles/map.module.scss';
import logo from '../images/logo-nom.png';

const center = {
  lat: 52.0989039784332,
  lng: 5.211476856187527,
};

const ClientMap = () => {
  const key = process.env.GATSBY_GOOGLE_MAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&language=nl`;
  return <Map
    googleMapURL={url}
    loadingElement={<div style={{ height: '56vh', width: '100%' }} />}
    containerElement={<div style={{ height: '56vh', width: '100%' }} />}
    mapElement={<div style={{ height: '100%' }} />}
  >
    <Marker position={center}>
      <InfoWindow>
        <InfoWindowContents />
      </InfoWindow>
    </Marker>
  </Map>
}

const Map = withScriptjs(withGoogleMap(({ children }) => {
  const defaultProps = {
    center,
    zoom: 17,
  };
  return <GoogleMap
    defaultCenter={defaultProps.center}
    defaultZoom={defaultProps.zoom}
  >
    {children}
  </GoogleMap>
}))

const InfoWindowContents = () => {
  return <div
    className={styles.infoWindow}
  >
    <img
      className={styles.logo}
      src={logo}
      alt='Nationale Onderwijs Monitors'
    />
    <p>
      Utrechtseweg 2<br/>
      3732 HB De Bilt<br/>
      <br/>
      <a href="https://www.google.com/maps/dir//Utrechtseweg+2,+3732+HB+De+Bilt/@52.0989386,5.2092667,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c668419317b463:0x4a8c3e0600169a27!2m2!1d5.2114554!2d52.0989386!3e0">
        Routebeschrijving
      </a>
    </p>
  </div>
}

export default ClientMap;
