import React from 'react';

import styles from '../styles/dashboard-preview-frame.module.scss';

const DashboardPreviewFrame = () => {
	return <div className={styles.frame}>
		<iframe className={styles.iframe} src="https://app.powerbi.com/view?r=eyJrIjoiN2I4MWQzNWQtYWYxMC00NTFhLWExMDUtMjAxMWY3N2E2ZWUwIiwidCI6IjJkNmE2NTljLWI3MjItNGRjOC04N2FkLTZhNjc3ZTIxNjgwYyIsImMiOjh9" frameborder="0" allowFullScreen="true"></iframe>
	</div>;
}

export default DashboardPreviewFrame;
