import React from 'react'
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

import Content from './content';

import styles from '../styles/partners.module.scss';

const Partners = (props) => {
	return <StaticQuery
	  query={partnersQuery}
	  render={ data => {
	    const partners = data.allContentfulPartner.edges.map(edge => {
	      return <PartnerListItem key={edge.node.id} {...edge.node} />
	    })
	    return <div className={styles.partners}>
				<Content
					{...props}
				/>
				<div className={styles.partnersList}>
					{partners}
				</div>
			</div>
	  }}
	/>
}

const PartnerListItem = ({ type, name, body, website, logo }) => {
	return <div className={styles.partnersListItem}>
    <div className={styles.logo}>
      <Img {...logo} />
    </div>
    <div className={styles.info}>
      <h3>{name}</h3>
      {!!body && <Html {...body.childMarkdownRemark} />}
    </div>
  </div>
}

const Html = ({ html }) => (
  <div
    className={styles.html}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

PartnerListItem.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

const partnersQuery = graphql`
  query Partners {
    allContentfulPartner {
      edges {
        node {
          id
          type
          name
          body {
            childMarkdownRemark {
              html
            }
          }
          logo {
            id
            title
            fluid(maxWidth: 320, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export { Partners as default };
