import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Content from '../components/content';

import Cases from '../components/cases';
import Team from '../components/team';
import Map from '../components/map';
import Partners from '../components/partners';
import Contact from '../components/contact';
import NewsArticles from '../components/news-articles';
import DashboardPreviewFrame from '../components/dashboard-preview-frame';
import Customers from '../components/customers';

const components = {
  'ervaringen-van-onze-klanten': Cases,
  'ons-team': Team,
  'map': Map,
  'onze-partners': Partners,
  'contact': Contact,
  'nieuws-artikelen': NewsArticles,
  'dashboard-preview-frame': DashboardPreviewFrame,
  'klanten-lijst': Customers,
}

const ContentPage = ({ pageContext, data }) => {
  const contentData = data.allContentfulContent || { edges: [] };
  const contentBySlug = contentData.edges.reduce((map, edge) => {
    map[edge.node.slug] = edge.node;
    return map;
  }, {});

  const contents = pageContext.contentSlugs.map(slug => {
    const DynamicComponent = components[slug];
    const node = contentBySlug[slug];

    // use dynamic comp if available
    if (DynamicComponent) {
      return <DynamicComponent
        key={slug}
        slug={slug}
        {...node}
      />
    }

    if (node) {
      return <Content
        key={slug}
        data={node}
        {...node}
      />
    }

    return <div>Oh no... I don't know what to render here.</div>
  });

  return <Layout>
    <SEO title={pageContext.title} keywords={[]} />
    {contents}
  </Layout>
}

ContentPage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    contentSlugs: PropTypes.array.isRequired,
  }),
  data: PropTypes.shape({
    allContentfulContent: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }),
}

export const pageQuery = graphql`
  query($contentSlugs: [String!]) {
    allContentfulContent(
      filter: { slug: { in: $contentSlugs} },
      sort: { fields: [createdAt] order: ASC }
      ) {
      edges {
        node {
          id
          slug
          title
          textColor
          intro {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          bodyColumnTwo {
            childMarkdownRemark {
              html
            }
          }
          bodyColumnThree {
            childMarkdownRemark {
              html
            }
          }
          image {
            id
            fluid(maxWidth: 1440, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`

export default ContentPage
