import React from 'react'
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

import Card from './card';
import Content from './content';

import styles from '../styles/news-articles.module.scss';

const NewsArticles = ({ slug }) => {
	return <StaticQuery
	  query={newsArticlesQuery}
	  render={ data => {
	    const newsArticles = data.allContentfulNewsArticle.edges.map(edge => {
	      return <NewsArticleListItem key={edge.node.id} {...edge.node} />
	    })
	    return <div className={styles.newsArticles}>
				<Content
					slug={slug}
					title='Nieuwsoverzicht'
				/>
				<div className={styles.newsArticleList}>
					{newsArticles}
				</div>
				</div>
	  }}
	/>
}

const NewsArticleListItem = (data) => {
	return <Link
    className={styles.newsArticleListItem}
    to={`nieuws/${data.slug}`}
  >
    <Img className={styles.newsArticleListItemImage} {...data.image} />
		<div className={styles.newsArticleListItemText}>
      <p className={styles.articleDate}>{data.date}</p>
      <h3 className={styles.articleTitle}>{data.title}</h3>
      {!!data.intro && <Html {...data.intro.childMarkdownRemark} />}
      <p className={styles.articleReadMore}>Lees meer</p>
    </div>

	</Link>
}

const Html = ({ html }) => (
  <div
    className={styles.html}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

NewsArticles.propTypes = {
  slug: PropTypes.string.isRequired,
}

const newsArticlesQuery = graphql`
  query NewsArticles {
    allContentfulNewsArticle {
      edges {
        node {
          id
          slug
          title
          date(formatString: "D MMMM YYYY", locale: "nl-NL")
          intro: excerpt {
            childMarkdownRemark {
              html
            }
          }
          image {
            id
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export { NewsArticles as default };
