import React from 'react';

import Content from './content';
import Card from './card';

import styles from '../styles/contact.module.scss';

const Contact = (props) => {
  return <div className={styles.contact}>
    <Content
      {...props}
    >
      <div className={styles.contactForms}>
        <ContactFormQuestion />
        {/*<ContactFormNewsletter />*/}
        {/*<ContactFormEvent />*/}
      </div>
    </Content>

  </div>
}

const ContactFormCard = ({ children }) => {
  return <div className={styles.contactFormCard}>
    <Card frontside={children} />
  </div>
}

const DefaultFormFields = () => (
  <React.Fragment>
    <input type="hidden" name="bot-field" />
    <label htmlFor="first-name">Voornaam</label>
    <input required type="text" id="first-name" name="first-name" placeholder="Voornaam" />
    <label htmlFor="last-name">Achternaam</label>
    <input required type="text" id="last-name" name="last-name" placeholder="Achternaam" />
    <label htmlFor="email">Email</label>
    <input required type="text" id="email" name="email" placeholder="Email" />
  </React.Fragment>
);

// ik heb een vraag
const ContactFormQuestion = () => {
  return <div className={styles.contactForm}>
    <h2>Ik heb een vraag</h2>
    <ContactFormCard>
      <form action="/contact/bedankt" name="question" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className={styles.form}>
        <input type="hidden" name="form-name" value="question" />
        <input type="hidden" name="subject" value="Iemand heeft een vraag gesteld" />
        <DefaultFormFields />
        <label htmlFor="telephone">Telefoonnummer</label>
        <input type="text" id="telephone" name="telephone" placeholder="Telefoonnummer" />
        <label htmlFor="organization">Organisatienaam</label>
        <input type="text" id="organization" name="organization" placeholder="Organisatienaam" />
        <label htmlFor="question">Je vraag</label>
        <textarea id="question" rows="3" name="question" placeholder="Je vraag" />
        <input type="submit" value="Verstuur" />
      </form>
    </ContactFormCard>
  </div>
}

// aanmelden nieuwsbrief
const ContactFormNewsletter = () => {
  return <div className={styles.contactForm}>
    <h2>Ik wil me aanmelden voor de nieuwsbrief</h2>
    <ContactFormCard>
      <form action="/contact/bedankt" name="newsletter" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className={styles.form}>
        <input type="hidden" name="form-name" value="newsletter" />
        <input type="hidden" name="subject" value="Iemand heeft aangemeld voor de nieuwsbrief" />
        <DefaultFormFields />
        <input type="submit" value="Aanmelden" />
      </form>
    </ContactFormCard>
  </div>
}

// aanmelden event
const ContactFormEvent = () => {
  return <div className={styles.contactForm}>
    <h2>Ik wil me aanmelden voor een event</h2>
    <ContactFormCard>
      <form action="/contact/bedankt" name="event" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className={styles.form}>
        <input type="hidden" name="form-name" value="event" />
        <input type="hidden" name="subject" value="Iemand heeft zich aangemeld voor een event" />
        <DefaultFormFields />
        <label htmlFor="telephone">Telefoonnummer</label>
        <input type="text" id="telephone" name="telephone" placeholder="Telefoonnummer" />
        <label htmlFor="organization">Organisatienaam</label>
        <input type="text" id="organization" name="organization" placeholder="Organisatienaam" />
        <label htmlFor="event">Naam van het event</label>
        <input type="text" id="event" name="event" placeholder="Naam van het event" />
        <input type="submit" value="Aanmelden" />
      </form>
    </ContactFormCard>
  </div>
}


export default Contact;
