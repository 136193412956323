import React from 'react'
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

import Content from './content';
import Card from './card';

import iconEmail from '../images/icon-email.svg';
import iconPhone from '../images/icon-phone.svg';
import iconLinkedIn from '../images/icon-linked-in.svg';

import styles from '../styles/team.module.scss';

const Team = ({ slug, title, image, intro }) => {
  return <StaticQuery
    query={teamQuery}
    render={ data => {
      const team = data.allContentfulTeamMember.edges.map(edge => {
        return <TeamMember key={edge.node.id} {...edge.node} />
      })
      return <div className={styles.team}>
        <Content
          slug={slug}
          title={title}
          intro={intro}
          image={image}
        />
        <div className={styles.teamList}>
          {team}
        </div>
      </div>
    }}
  />
}

Team.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.object,
}

const TeamMember = ({ name, role, image, quote, email, mobile, linkedin }) => {
  const frontside = (
    <React.Fragment>
      <div className={styles.image}>
        {!!image && <Img {...image} />}
      </div>
      <h3>{name}</h3>
      <p className={styles.role}>{role}</p>
    </React.Fragment>
  );

  const backside = (
    <React.Fragment>
      <p className={styles.quote}>{quote}</p>
      <div className={styles.personalData}>
        <h3>{name}</h3>
        <p className={styles.role}>{role}</p>
        {!!mobile && (<p className={styles.icon}>
          <a href={`tel:${mobile}`}>
            <img src={iconPhone} alt='Mobiel' />&nbsp; {mobile}
          </a>
        </p>)}
        {!!email && (<p className={styles.icon}>
          <a href={`mailto:${email}`}>
            <img src={iconEmail} alt='Email' />&nbsp; Email aan {name}
          </a>
        </p>)}
        {!!linkedin && (<p className={styles.icon}>
          <a href={linkedin}>
            <img src={iconLinkedIn} alt='LinkedIn' />&nbsp; LinkedIn pagina
          </a>
        </p>)}
      </div>
    </React.Fragment>
  );

  return <div className={styles.teamMember}>
    <Card frontside={frontside} backside={backside} />
  </div>
}

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string,
}

const teamQuery = graphql`
  query Team {
    allContentfulTeamMember {
      edges {
        node {
          id
          name
          role
          image {
            id
            title
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          mobile
          email
          linkedin
          quote
        }
      }
    }
  }
`;

export default Team;
